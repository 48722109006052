.free-customization-snipe {
    font-weight:bold;
    line-height: 1rem;
    position: absolute;
    top: 60px;
    left: 30px;
    right: 30px;
    //transform: rotate(4deg);
    margin: auto;
    color: white;
    color: green;
    background-color: white;
    border: 2px green solid;
    text-align: center;
    text-transform: uppercase;
    justify-content:center;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 6px;
    animation: flickerAnimation 1.5s infinite;

    @media(max-width:575.98px) {
        left: 10px;
        right: 10px;
        font-size: 12px;
        line-height: 0.7rem;
    }

    @media(max-width: 383.98px) {
        top: 10px;
        left: 10px;
        right: 10px;
        font-size: 12px;
        line-height: 0.7rem;
    }

    @media(max-width: 350.98px) {
        left: 0px;
        right: 0px;
        font-size: 12px;
        line-height: 0.7rem;
    }

}

@keyframes flickerAnimation { /* flame pulses */
  0%   { opacity:0.8; }
  40%   { opacity:0; }
  60%  { opacity:0; }
  100% { opacity:0.8; }
}

.product-item {
    position: relative;
    @media(max-width: 991.98px) {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    >div:first-child {
        position: relative;

        button {
            cursor: pointer;
            padding-top: 8px;
            padding-bottom: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            opacity: 0;
            transition-duration: .45s;
            @media(max-width:575.98px) {
                width: 100%;
                min-width: auto;
            }
        }

        &:hover {
            button {
                opacity: 1;
            }
        }

        img {
            cursor: pointer;
        }
    }

    &:hover > div:first-child {
        @media(min-width:481px) {
            position: relative;
            button {
                opacity: 1;
            }
        }
    }

    border: 1px solid var(--gray);
    padding: 20px 15px;
    margin-bottom: 1.5rem;

    h4 {
        margin-top: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
    }

    h3 {
        font-size: 15px;
        font-weight: 400;
    }

    p {
        font-size: 12px;
        line-height: 16px;
    }

    button.info-button {
        font-weight: 600;
        background-color: transparent;
        border: none;
        text-align: left;

        @media(max-width:575.98px) {
            font-size: 15px;
            line-height: 1.15;
        }

        @media(max-width: 383.98px) {
            font-size: 14px;
        }

        @media(max-width: 350.98px) {
            font-size: 13px;
        }

        img {
            @media(max-width:575.98px) {
                width: 23px;
            }
        }
    }

    >button:last-child {
        margin-top: 20px;
        font-weight: 600;
    }

    &.selected {
        >div {
            width: 110px;
            display: block;
            margin-left: auto;

        }

        h4 {
            margin-top: -4px;

            @media(max-width: 479.98px) {
                margin-top: 5px;
            }
        }

    }

    .item-info {
        button {
            font-weight: 700;
            background-color: transparent;
            border: none;
            text-align: left;

            @media(max-width:575.98px) {
                font-size: 13px;
            }

            @media(max-width: 383.98px) {
                font-size: 12px;
            }
        }
    }
}

.modal-product {
    border-radius: 8px;
    padding: 15px 10px 12px;
    flex:1;
    position: relative;

    h4 {
        font-size: 10px;
        font-weight: 700;
        margin-top: 10px;
    }

    h3 {
        font-size: 15px;
        font-weight: 400;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 7px;
        margin-top: 15px;
    }

    button {
        width: 100%;
        min-width: 100%;
    }

}


.product-change-modal {
    // ul {
    //     padding: 0;
    //     margin: 0;

    //     li {
    //         align-items: center;
    //         font-weight: 500;
    //         font-size: 15px;
    //         margin-bottom: 10px;
    //         cursor: pointer;

    //         label {
    //             padding-top: 4px;

    //             span {
    //                 &:first-of-type {
    //                     margin-right: 8px;
    //                     border: 1px solid #DFDFDF;
    //                     width: 17px;
    //                     height: 17px;
    //                     color: #ada9a9;
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: center;
    //                     padding-top: 2.5px;
    //                     font-size: 12px;
    //                     font-weight: 700;

    //                     i {
    //                         display: none;
    //                     }
    //                 }

    //                 &:last-child {
    //                     font-weight: 500;
    //                 }
    //             }

    //             input:checked {
    //                 +span {
    //                     &:first-of-type {
    //                         i {
    //                             display: block;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}